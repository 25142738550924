import React from 'react'

// Conmponents
import Badge from '../../../../../../components/UI/Badge'

const BankAddress = () => {
    return (
      <section className='py-2 bg-gray'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-8'>
              <h3 className='mt-5'>Have another card machine?<br /> Change your bank address.</h3>
              <p>If you already have an account with us, transfer your address from the creditor to Banco Inter. If you still don’t have an account, open it now!</p>
              <div className='mt-4 mb-5'>
                <ul className='list-unstyled'>
                  <li className='d-flex align-items-center mb-4'>
                    <Badge size='large' icon='zero' />
                    <p className='fs-20'>Free of charges</p>
                  </li>
                  <li className='d-flex align-items-center mb-4'>
                    <Badge size='large' icon='transfer' />
                    <p className='fs-20'>Agreement with the main card machine companies</p>
                  </li>
                  <li className='d-flex align-items-center'>
                    <Badge size='large' icon='hand' />
                    <p className='fs-20'>Advanced payment possibility</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  export default BankAddress
