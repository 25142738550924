import React from 'react'
import Img from 'gatsby-image'

// Style
import { ImgSize } from './style'

// Images
import usePageQuery from '../../pageQuery'

const FriendlyBank = () => {
    const data = usePageQuery()
    return (
      <section className='py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-8'>
              <h3>The individual microenterprise needs a friendly bank even more</h3>
              <p>For this reason, your Microenterprise can have a Banco Inter digital account 100% free of charge. If you already have a personal account, its simple and quick to create your MEI account.</p>
              <p>For new requests, we have good news: To open your MEI Digital Account you can use the App of your personal account. See the step by step:</p>
              <ImgSize className='mt-5 mb-5 d-flex justify-content-center justify-content-xl-start'>
                <Img fluid={data.OpenAccountSteps.fluid} alt='Step by step' />
              </ImgSize>
              <h3>Designed to make your business every day easier</h3>
              <p>The Microenterprise digital account has the essential services for running your business. The best of it? You do everything in the app and you don’t have to pay anything for it.</p>
              <ul>
                <li>Up to 100 free transfers monthly</li>
                <li>Agreement with the main card machine companies, for receiving your sales – be it credit or debit</li>
                <li>Check deposit by image</li>
                <li>Cash deposit via invoice</li>
                <li>Up to 100 free invoices per month</li>
                <li>Invoice payment</li>
                <li>Interpag: receive payments through QR Code, free of charge</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }

  export default FriendlyBank
