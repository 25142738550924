import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    @media ${device.tablet} {
      max-width: 650px;
    }
    @media ${device.desktopLG} {
      width: 615px;
    }
    @media ${device.desktopXL} {
      width: 650px;
    }
  }
`