import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'

// PageContext
import pageContext from './pageContext.json'

// Components
import Hero from './sections/header/_hero'
import FriendlyBank from './sections/friendly-bank/_friendly-bank'
import BankAddress from './sections/bank-address/_bank-address'

const Cambio = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <FriendlyBank />
        <BankAddress />
      </Layout>
    </Wrapper>
  )
}

export default Cambio
