import React from 'react'
import { ContentHeader } from './style'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

const Hero = () => {
  return (
    <ContentHeader>
      <div className='container'>
        <div className='col-lg-6 col-md-9 pl-0 pb-2'>
          <div className='d-flex align-items-center mb-3'>
            <OrangeIcon size='LD' color='#FFFFFF' icon='smartphones' />
            <p className='text-white fs-sm-18 fs-16 fw-300 ml-2 mb-0'>Digital Account</p>
          </div>
          <h1 className='text-white fs-30 fs-md-50 fw-600'>Microenterprise Account</h1>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
